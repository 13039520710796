import { Entity, IEntity } from './entity';
import { ILanguage, Language } from './language';
import { OrganizationUnitTypeData } from './organization-unit-type-data';
import { OrganizationUnitTypeType } from './types';

export interface IOrganizationUnit extends IEntity {
  id: string;
  name: string;
  language: ILanguage | undefined;
  type: string;
  editable: boolean;
}

export class OrganizationUnit extends Entity implements IOrganizationUnit {
  name: string;
  language: Language | undefined;
  type: OrganizationUnitTypeType;

  get editable() {
    return this.type !== OrganizationUnitTypeData.getByName('xunta')?.name &&
      this.type !== OrganizationUnitTypeData.getByName('technicalOffice')?.name;
  }

  constructor(data: IOrganizationUnit) {
    super(data);
    this.name = data.name;
    this.type = data.type;
    this.language = data.language != null
      ? new Language(data.language)
      : undefined
  }
}
