export const environment = {
  production: false,
  api: {
    baseUrl: 'https://v-galicia-api-dev.azurewebsites.net/api/v1',
  },
  auth: {
    realm: 'GaliciaDev',
    authority: 'https://auth.plataformatecnoloxicapoloclima.gal',
    redirectUri: 'https://plataformatecnoloxicapoloclima.gal',
    postLogoutRedirectUri: 'https://plataformatecnoloxicapoloclima.gal',
    clientId: 'galicia-web-dev',
  },
  availablePageSizes: [20, 50, 100],
};
