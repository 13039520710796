import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { ApiService } from './api-service.service';
import { environment } from '../../../environments/environment';
import {Company, ICompany, IEntityList, ISector, Sector} from '../models';
import {
  ICreateCompanyCommand,
  IUpdateCompanyCommand,
} from '../models/commands/company-command';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends ApiService {
  private readonly url = `${environment.api.baseUrl}/companies`;
  private readonly urlAlt = `${environment.api.baseUrl}/company`;

  getAll(
    langCode: string,
    filters: string | null = null
  ): Observable<IEntityList<ICompany>> {
    const url = `${this.url}?languageCode=${langCode}${filters ? `&filterBase64=${filters}` : ''}`;
    return this.get<IEntityList<ICompany>>(url);
  }

  search(
    langCode: string,
    filters: string | null = null
  ): Observable<IEntityList<ICompany>> {
    const url = `${this.url}/search`;
    const data = {
      languageCode: langCode,
      filterBase64: filters ? filters : ''
    }
    return this.post<IEntityList<ICompany>>(url, data).pipe(
      map(entityList => ({
        ...entityList,
        items: entityList.listObject.map(item => new Company(item))
      }))
    );
  }

  toggleIsActive(
    id: string,
    command: { isActive: boolean }
  ): Observable<{ isActive: boolean }> {
    return this.patch<{ isActive: boolean }>(`${this.url}/${id}`, command);
  }

  getById(id: string, langCode: string): Observable<Company> {
    const url = `${this.urlAlt}/${id}?languageCode=${langCode}`;
    return this.get<ICompany>(url)
      .pipe(map(company => new Company(company)));
  }

  create(sector: ICreateCompanyCommand): Observable<ICompany> {
    return this.post<ICompany>(this.url, sector);
  }

  update(id: string, company: IUpdateCompanyCommand): Observable<ICompany> {
    return this.put<ICompany>(`${this.url}/${id}`, company);
  }

  updateMyCompany(company: IUpdateCompanyCommand): Observable<ICompany> {
    return this.put<ICompany>(`${this.url}/me`, company);
  }

  remove(id: string): Observable<boolean> {
    return this.delete(`${this.url}/${id}`);
  }
}
