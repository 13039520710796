import { Entity } from "../models";

export function getFlagImage(lang: string): string {
  return `./images/flag-${lang}.png`;
}

export function getScrollHeight(list: Entity[], itemHeight: number,
  numItemsDefault: number = 5, padding: number = 25)
  : { style: { 'min-height': string } } {
  const itemsLength = list.length;
  const height = itemsLength >= numItemsDefault
    ? numItemsDefault * itemHeight
    : itemsLength * itemHeight;

  return {
    style: {
      'min-height': `${height + padding}px`
    }
  };
}

export function filterSelected<T extends Entity>(list: T[], selectedIds: string[]): T[] {
  return list.filter((item) => selectedIds.includes(item.id));
}
