import {Entity, IEntity} from "./entity";


export interface IBaseFile extends IEntity {
  name: string;
  lastUpdate: Date;
  relativePath: string;
  absolutePath: string;
}

export class BaseFile extends Entity implements IBaseFile {
  name: string;
  lastUpdate: Date;
  relativePath: string;
  absolutePath: string;

  constructor(data: IBaseFile) {
    super(data);
    this.name = data.name;
    this.lastUpdate = data.lastUpdate;
    this.relativePath = data.relativePath;
    this.absolutePath = data.absolutePath;
  }
}
