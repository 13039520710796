import { Content, IContent } from "./content";
import { ILocaleField } from "./locale-field";

export interface INewsletter extends IContent {
  subject:string;
  body:string;
  localeSubject: ILocaleField[];
  localeBody: ILocaleField[];
}

export class Newsletter extends Content implements INewsletter{
  public subject: string;
  public body: string;
  public localeSubject: ILocaleField[];
  public localeBody: ILocaleField[];

  constructor(data: INewsletter) {
    super(data);
    this.subject = data.subject;
    this.body = data.body;
    this.localeSubject = data.localeSubject;
    this.localeBody = data.localeBody
  }
}
