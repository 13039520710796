import { OrganizationUnitTypeData } from "../organization-unit-type-data";

export const OrganizationUnitType = {
  TECHNICALOFFICE: 'technicalOffice',
  CITYHALL: 'cityHall',
  COMPANY: 'company',
  XUNTA: 'xunta',
} as const;

export type OrganizationUnitType =
  (typeof OrganizationUnitType)[keyof typeof OrganizationUnitType];

const types = OrganizationUnitTypeData.getAllTypes().map(type => type.name);
export type OrganizationUnitTypeType = typeof types[number];
