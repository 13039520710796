export class CircleOptions {
  center: google.maps.LatLngLiteral;
  radius: number;
  strokeWeight: number = 2;
  strokeColor: string = 'black';
  strokeOpacity: number = 1;
  fillColor: string = 'black';
  fillOpacity: number = 0.8;

  constructor(center: google.maps.LatLngLiteral, radius: number) {
    this.center = center;
    this.radius = radius;
  }
}

export interface ILocation {
  lat: number;
  lng: number;
}

export interface IGeocondingInfo {
  positionByPostalCode: ILocation,
  bounds: google.maps.LatLngBoundsLiteral,
  locality: string;
  province: string;
  postalCode: string;
}

export class GeocondingInfo implements IGeocondingInfo {
  result: google.maps.GeocoderResult;
  position: google.maps.LatLngLiteral | undefined;

  get positionByPostalCode(): ILocation {
    return {
      lat: this.result.geometry.location.lat(),
      lng: this.result.geometry.location.lng()
    } as ILocation;
  }

  get bounds(): google.maps.LatLngBoundsLiteral {
    return this.result.geometry.bounds?.toJSON() ??
      { north: 0, south: 0, east: 0, west: 0 };
  }

  get locality(): string {
    return this.extractAddressDataByType('locality') ?? '';
  }

  get province(): string {
    const province = this.extractAddressDataByType('administrative_area_level_2');
    return province ?? this.extractAddressDataByType('administrative_area_level_1') ?? '';
  }

  get postalCode(): string {
    return this.extractAddressDataByType('postal_code') ?? '';
  }

  get radius(): number {
    const centerLat = (this.bounds.north + this.bounds.south) / 2;
    const centerLng = (this.bounds.east + this.bounds.west) / 2;

    return google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(centerLat, centerLng),
      new google.maps.LatLng(this.bounds.north, centerLng),
    );
  }

  get sliceSize(): number {
    return this.radius / 7;
  }

  constructor(result: google.maps.GeocoderResult) {
    this.result = result;
  }

  private extractAddressDataByType(type: string): string | undefined {
    const { address_components } = this.result;
    const component = this.getComponentAddressByType(address_components, type);

    return component
      ? component.long_name
      : undefined;
  }

  private getComponentAddressByType(
    addressComponents: google.maps.GeocoderAddressComponent[],
    type: string
  ) : google.maps.GeocoderAddressComponent | undefined {
    return addressComponents.find((component: google.maps.GeocoderAddressComponent) =>
      component.types.includes(type)
    );
  }
}
