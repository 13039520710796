import { Entity } from '.';

export interface IEntityList<T extends Entity> {
  totalRows: number;
  listObject: T[];
}

export class EntityList<T extends Entity> implements IEntityList<T> {
  listObject: T[];
  totalRows: number;

  constructor(data: IEntityList<T>) {
    this.listObject = data.listObject;
    this.totalRows = data.totalRows;
  }
}
