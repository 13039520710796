export function toTitleCase(str: string): string {
  if (!str) return str;

  const excludedWords = ['de', 'del', 'y', 'o', 'un', 'uno', 'una', 'unha'];

  return str.toLowerCase().replace(/\b\p{L}+/gu, (word) => {
    if (excludedWords.includes(word)) {
      return word;
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  });
}

export function capitalize(str: string): string {
  if (!str) return str;

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
