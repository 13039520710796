export interface IEntity {
  id: string;
  createdAt: Date;
  updatedAt: Date | undefined;
}

export abstract class Entity implements IEntity {
  id: string;
  createdAt: Date;
  updatedAt: Date | undefined;

  protected constructor(data: IEntity) {
    this.id = data.id;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}

export interface IActivable {
  isActive: boolean;
}
