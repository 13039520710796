import { AbstractControl, ValidatorFn } from '@angular/forms';

export function timeRangeValidator(startField: string, endField: string): ValidatorFn {
  return (formGroup: AbstractControl): { [key: string]: any } | null => {
    const startDate = formGroup.get(startField)?.value;
    const endDate = formGroup.get(endField)?.value;
    if (startDate && endDate && startDate >= endDate) {
      return { timeRangeInvalid: true };
    }
    return null;
  };
}
