import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class LanguagePreferencesService {
  private readonly sessionLangKey = 'selectedLang';

  setManualLangChange(langCode: string) {
    sessionStorage.setItem(this.sessionLangKey, langCode);
  }

  getManualLangChange() {
    return sessionStorage.getItem(this.sessionLangKey);
  }

  clearManualLangChange() {
    sessionStorage.removeItem(this.sessionLangKey);
  }
}
