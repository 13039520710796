import { Entity, IActivable, IEntity } from '.';
import { ILocaleField } from './locale-field';

export interface IPopulationLevel extends IEntity, IActivable {
  name: string;
  isActive: boolean;
  order: number;
  localeName: ILocaleField[];
}

export class PopulationLevel extends Entity implements IPopulationLevel {
  name: string;
  isActive: boolean;
  order: number;
  localeName: ILocaleField[] = [];

  constructor(data: IPopulationLevel) {
    super(data);
    this.name = data.name;
    this.isActive = data.isActive;
    this.localeName = data.localeName;
    this.order = data.order
  }
}
