import { KeycloakConfig, KeycloakInitOptions } from 'keycloak-js';
import { environment } from '../../environments/environment';

export const keycloakConfig: KeycloakConfig = {
  url: environment.auth.authority,
  realm: environment.auth.realm,
  clientId: environment.auth.clientId,
};

export const keycloakInitOptions: KeycloakInitOptions = {
  onLoad: 'check-sso',
  pkceMethod: 'S256',
  silentCheckSsoRedirectUri: window.location.origin + '/silent-refresh.html',
  redirectUri: environment.auth.redirectUri,
};
