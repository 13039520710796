export const MessageActionState = {
  Successfully: 'messageActionStates.successfully',
  ErrorCreated: 'messageActionStates.errorCreated',
  ErrorUpdated: 'messageActionStates.errorUpdated',
  ErrorDeleted: 'messageActionStates.errorDeleted',
  ErrorActivated: 'messageActionStates.errorActivated',
  ErrorDeactivated: 'messageActionStates.errorDeactivated',
  ErrorUploaded: 'messageActionStates.errorUploaded',
  ErrorSubscribed: 'messageActionStates.errorSubscribed',
  ErrorDownloaded: 'messageActionStates.errorDownloaded',
  ErrorSended: 'messageActionStates.errorSended',
} as const;

export type MessageActionStateKey =
  (typeof MessageActionState)[keyof typeof MessageActionState];
export const messageActionStatesKeys: MessageActionStateKey[] =
  Object.values(MessageActionState);
