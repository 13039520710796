import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '.';
import { environment } from '../../../environments/environment';
import { IEntityList, IPopulationLevel } from '../models';
import {
  ICreatePopulationLevelCommand,
  IUpdatePopulationLevelCommand,
} from '../models/commands/population-level-command';

@Injectable({
  providedIn: 'root',
})
export class PopulationLevelService extends ApiService {
  private readonly url = `${environment.api.baseUrl}/population-levels`;

  getAll(
    langCode: string,
    filters: string | null = null
  ): Observable<IEntityList<IPopulationLevel>> {
    const url = `${this.url}?languageCode=${langCode}${filters ? `&filterBase64=${filters}` : ''}`;
    return this.get<IEntityList<IPopulationLevel>>(url);
  }

  getById(id: string, langCode: string): Observable<IPopulationLevel> {
    const url = `${this.url}/${id}?languageCode=${langCode}`;
    return this.get<IPopulationLevel>(url);
  }

  toggleIsActive(
    id: string,
    command: { isActive: boolean }
  ): Observable<{ isActive: boolean }> {
    return this.patch<{ isActive: boolean }>(`${this.url}/${id}`, command);
  }

  create(
    populationLevel: ICreatePopulationLevelCommand
  ): Observable<IPopulationLevel> {
    return this.post<IPopulationLevel>(this.url, populationLevel);
  }

  update(
    id: string,
    populationLevel: IUpdatePopulationLevelCommand
  ): Observable<IPopulationLevel> {
    return this.put<IPopulationLevel>(`${this.url}/${id}`, populationLevel);
  }

  remove(id: string): Observable<boolean> {
    return this.delete(`${this.url}/${id}`);
  }
}
