import { ILocaleField } from './locale-field';
import { Entity, IActivable, IEntity } from './entity';

export interface ICommunityType extends IEntity, IActivable {
  name: string;
  localeName: ILocaleField[];
}

export class CommunityType extends Entity implements ICommunityType {
  name: string;
  isActive: boolean;
  localeName: ILocaleField[] = [];

  constructor(data: ICommunityType) {
    super(data);
    this.name = data.name;
    this.isActive = data.isActive;
    this.localeName = data.localeName;
  }
}
