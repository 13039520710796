import {
  Characteristic,
  IActivable,
  ICharacteristic,
  IOrganizationUnit,
  IPopulationLevel,
  OrganizationUnit,
  PopulationLevel,
} from '.';
import {ILocation} from './geocodingInfo';

export interface ICityHall extends IOrganizationUnit, IActivable {
  id: string;
  name: string;
  website: string;
  postalCode: string;
  location: ILocation;
  locality: string;
  province: string;
  cif: string;
  isActive: boolean;
  characteristics: ICharacteristic[];
  populationLevel: IPopulationLevel | undefined;
}

export class CityHall extends OrganizationUnit implements ICityHall {
  isActive: boolean;
  cif: string;
  website: string;
  postalCode: string;
  location: ILocation;
  locality: string;
  province: string;
  characteristics: ICharacteristic[] = [];
  populationLevel: PopulationLevel | undefined;

  get characteristicNames(): string {
    if (this.characteristics.length === 0) {
      return '';
    }

    const names = this.characteristics.map(c => c.name);
    const maxLength = 50;

    let truncatedNames = names;
    if (names.join(', ').length > maxLength) {
      truncatedNames = names.join(', ').slice(0, maxLength).split(', ');
      truncatedNames.push('...');
    }

    const listItems = truncatedNames.map(name => `<li>${name}</li>`).join('');
    return `<ul>${listItems}</ul>`;
  }

  constructor(data: ICityHall) {
    super(data);
    this.cif = data.cif;
    this.name = data.name;
    this.website = data.website;
    this.postalCode = data.postalCode;
    this.location = data.location;
    this.locality = data.locality;
    this.province = data.province;
    this.isActive = data.isActive;
    this.characteristics = Array.isArray(data.characteristics)
      ? data.characteristics.map((item: any) => new Characteristic(item))
      : [];
    this.populationLevel = data.populationLevel
      ? new PopulationLevel(data.populationLevel)
      : undefined;
  }
}
