import { CircleOptions, GeocondingInfo } from "../models/geocodingInfo";

export function createImgPinElement(imgPath: string): HTMLElement {
  const iconElement = document.createElement('div');
  iconElement.innerHTML = imgPath;

  return iconElement;
}

export async function customGooglePinElement(options: google.maps.marker.PinElementOptions): Promise<HTMLElement> {
  const { PinElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
  const pin = new PinElement();

  if (options.background) {
    pin.background = options.background;
  }
  if (options.borderColor) {
    pin.borderColor = options.borderColor;
  }
  if (options.glyph) {
    pin.glyph = options.glyph;
  }
  if (options.glyphColor) {
    pin.glyphColor = options.glyphColor;
  }
  if (options.scale) {
    pin.scale = options.scale;
  }

  return pin.element;
}

export function getResult(results: google.maps.GeocoderResult[]): GeocondingInfo {

  const geocodingInfoList = results.map((result: google.maps.GeocoderResult) => new GeocondingInfo(result));

  for (const geocodingInfo of geocodingInfoList) {
    if (
      geocodingInfo.postalCode &&
      geocodingInfo.locality &&
      geocodingInfo.province &&
      geocodingInfo.bounds &&
      geocodingInfo.positionByPostalCode
    ) {
      return geocodingInfo;
    }
  }
  return geocodingInfoList[0];
}

export function getGradientCircle(center: google.maps.LatLngLiteral, sliceSize: number): CircleOptions[]{
  const colors = [
    '#016206',
    '#05780a',
    '#018d0a',
    '#018d0a',
    '#00aa01',
    '#6bc062',
    '#6bc062'
  ];

  return colors.map((color, index) => {
    const circle = new CircleOptions(center, sliceSize * (index + 1));
    circle.strokeWeight = 0;
    circle.fillColor = color;
    circle.fillOpacity = 0.1;
    return circle;
  });
}
