import { Injectable } from '@angular/core';
import Keycloak, { KeycloakProfile, KeycloakTokenParsed } from 'keycloak-js';
import { keycloakConfig, keycloakInitOptions } from '../auth.config';
import { PermissionsOperation, ProfileRoles } from '../models/app-permissions';
import { LanguagePreferencesService } from '../../shared/services';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private keycloak: Keycloak;

  constructor(
    private languagePreferences: LanguagePreferencesService
  ) {
    this.keycloak = new Keycloak(keycloakConfig);
  }

  public init(): Promise<boolean> {
    return this.keycloak.init(keycloakInitOptions);
  }

  public redirectToLogin(): void {
    this.keycloak.login();
  }

  public logout(): void {
    this.languagePreferences.clearManualLangChange();
    this.keycloak.logout();
  }

  public isLoggedIn(): Promise<boolean> {
    return Promise.resolve(this.keycloak.authenticated || false);
  }

  public getToken(): Promise<string | undefined> {
    return Promise.resolve(this.keycloak.token);
  }

  public loadUserProfile(): Promise<KeycloakProfile> {
    return this.keycloak.loadUserProfile();
  }

  public getUserRoles(): string[] | undefined {
    const token: KeycloakTokenParsed | undefined = this.keycloak.tokenParsed;
    if (token) {
      return token?.realm_access?.roles;
    }
    return undefined;
  }

  public hasRole(role: ProfileRoles): boolean {
    const roles = this.getUserRoles();
    if (roles) {
      return roles.includes(role);
    }
    return false;
  }

  public hasRoles(
    roles: ProfileRoles[],
    operation: PermissionsOperation
  ): boolean {
    if (operation === 'and') {
      return roles.every((role) => this.hasRole(role));
    } else {
      return roles.some((role) => this.hasRole(role));
    }
  }
}
