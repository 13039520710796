import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthService } from '../../auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export abstract class ApiService {
  protected constructor(
    protected http: HttpClient,
    protected authService: AuthService
  ) {}

  protected getHeaders(): Observable<HttpHeaders> {
    return from(this.authService.getToken()).pipe(
      map(
        (token) =>
          new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          })
      )
    );
  }

  // para no enviar content type y subir archivos
  public getHeadersFile(): Observable<HttpHeaders> {
    return from(this.authService.getToken()).pipe(
      map(
        (token) =>
          new HttpHeaders({
            Authorization: `Bearer ${token}`,
          })
      )
    );
  }

  protected handleError(error: any): Observable<never> {
    console.error(error);
    if (error && error.error) {
      return throwError(error.error);
    } else return throwError('An error ocurred. Please try again later');
  }

  protected get<T>(url: string): Observable<T> {
    return this.getHeaders().pipe(
      switchMap((headers) =>
        this.http.get<T>(url, { headers }).pipe(
          map((value) => value as T),
          catchError(this.handleError)
        )
      )
    );
  }

  protected post<T>(
    url: string,
    data: any,
    customHeaders: any = null
  ): Observable<T> {
    return this.getHeaders().pipe(
      switchMap((headers) => {
        const finalHeaders = customHeaders ? customHeaders : headers;
        return this.http.post<T>(url, data, { headers: finalHeaders }).pipe(
          map((value) => value as T),
          catchError(this.handleError)
        );
      })
    );
  }

  protected put<T>(url: string, data: any): Observable<T> {
    return this.getHeaders().pipe(
      switchMap((headers) =>
        this.http.put<T>(url, data, { headers }).pipe(
          map((value) => value as T),
          catchError(this.handleError)
        )
      )
    );
  }

  protected patch<T>(url: string, data: any): Observable<T> {
    return this.getHeaders().pipe(
      switchMap((headers) =>
        this.http.patch<T>(url, data, { headers }).pipe(
          map((value) => value as T),
          catchError(this.handleError)
        )
      )
    );
  }

  protected delete<T>(url: string): Observable<boolean> {
    return this.getHeaders().pipe(
      switchMap((headers) =>
        this.http
          .delete<boolean>(url, { headers })
          .pipe(catchError(this.handleError))
      )
    );
  }
}
