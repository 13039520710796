import {BaseFile, IBaseFile} from "./base-file";

export interface IActivityFile extends IBaseFile {
}

export class ActivityFile extends BaseFile implements IActivityFile {

  constructor(data: IActivityFile) {
    super(data);
  }
}
