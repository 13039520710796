import { Entity, IEntity } from "./entity";

export interface IUserActivityInscription extends IEntity{
  firstName: string;
  lastName: string;
  organization: string;
  activity: string;
  activityType: string;
  startTimeSlot: Date;
  endTimeSlot: Date;
  registrationDate: Date;
  comment: string;
}

export class UserActivityInscription extends Entity implements IUserActivityInscription {
  firstName: string;
  lastName: string;
  organization: string;
  activity: string;
  activityType: string;
  startTimeSlot: Date;
  endTimeSlot: Date;
  registrationDate: Date;
  comment: string;

  constructor(data: IUserActivityInscription) {
    super(data);
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.organization = data.organization;
    this.activityType = data.activityType;
    this.activity = data.activity;
    this.startTimeSlot = data.startTimeSlot;
    this.endTimeSlot = data.endTimeSlot;
    this.registrationDate = data.registrationDate;
    this.comment = data.comment;
  }
}
