import { Injectable, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import {
  ActionState,
  ActionStateKey,
  actionStatesKeys,
  MessageActionState,
  messageActionStatesKeys,
} from '../models/types';
import { capitalize } from '../Utils';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private actionStateTranslations = signal<{ [x: string]: string }>({});
  private messageActionStateTranslations = signal<{ [x: string]: string }>({});

  constructor(
    private messageService: MessageService,
    private translate: TranslateService
  ) {
    this.translate.stream(actionStatesKeys).subscribe((translations: any) => {
      this.actionStateTranslations.set(translations);
    });
    this.translate
      .stream(messageActionStatesKeys)
      .subscribe((translations: any) => {
        this.messageActionStateTranslations.set(translations);
      });
  }

  public showToast(
    severity: 'success' | 'info' | 'warn' | 'error',
    summary: ActionStateKey,
    detail: string
  ) {
    this.messageService.add({
      severity,
      summary: this.actionStateTranslations()[summary],
      detail: capitalize(detail),
    });
  }

  public toggleActivationToast(
    severity: 'success' | 'error',
    isActive: boolean,
    entityName: string
  ) {
    this.showToast(
      severity,
      isActive ? ActionState.Activated : ActionState.Deactivated,
      severity === 'success'
        ? `${entityName} ${
            isActive
              ? this.actionStateTranslations()[ActionState.Activated]
              : this.actionStateTranslations()[ActionState.Deactivated]
          } ${this.messageActionStateTranslations()[MessageActionState.Successfully]}.`
        : `${
            isActive
              ? this.messageActionStateTranslations()[
                  MessageActionState.ErrorDeactivated
                ]
              : this.messageActionStateTranslations()[
                  MessageActionState.ErrorActivated
                ]
          } ${entityName}.`
    );
  }

  public creationToast(severity: 'success' | 'error', entityName: string) {
    this.showToast(
      severity,
      severity == 'success' ? ActionState.Created : ActionState.Error,
      severity === 'success'
        ? `${entityName} ${this.actionStateTranslations()[ActionState.Created]} ${this.messageActionStateTranslations()[MessageActionState.Successfully]}.`
        : `${this.messageActionStateTranslations()[MessageActionState.ErrorCreated]} ${entityName}.`
    );
  }

  public updateToast(severity: 'success' | 'error', entityName: string) {
    this.showToast(
      severity,
      severity == 'success' ? ActionState.Updated : ActionState.Error,
      severity === 'success'
        ? `${entityName} ${this.actionStateTranslations()[ActionState.Updated]} ${this.messageActionStateTranslations()[MessageActionState.Successfully]}.`
        : `${this.messageActionStateTranslations()[MessageActionState.ErrorUpdated]} ${entityName}.`
    );
  }

  public uploadToast(severity: 'success' | 'error', entityName: string) {
    this.showToast(
      severity,
      severity == 'success' ? ActionState.Uploaded : ActionState.Error,
      severity === 'success'
        ? `${entityName} ${this.actionStateTranslations()[ActionState.Uploaded].toLowerCase()} ${this.messageActionStateTranslations()[MessageActionState.Successfully]}.`
        : `${this.messageActionStateTranslations()[MessageActionState.ErrorUploaded]} ${entityName}.`
    );
  }

  public downloadToast(severity: 'success' | 'error', entityName: string) {
    this.showToast(
      severity,
      severity == 'success' ? ActionState.Downloaded : ActionState.Error,
      severity === 'success'
        ? `${entityName} ${this.actionStateTranslations()[ActionState.Downloaded].toLowerCase()} ${this.messageActionStateTranslations()[MessageActionState.Successfully]}.`
        : `${this.messageActionStateTranslations()[MessageActionState.ErrorDownloaded]} ${entityName}.`
    );
  }

  public consultationToast(severity: 'success' | 'error', entityName: string) {
    this.showToast(
      severity,
      severity == 'success' ? ActionState.Sended : ActionState.Error,
      severity === 'success'
        ? `${entityName} ${this.actionStateTranslations()[ActionState.Sended].toLowerCase()} ${this.messageActionStateTranslations()[MessageActionState.Successfully]}.`
        : `${this.messageActionStateTranslations()[MessageActionState.ErrorSended]} ${entityName}.`
    );
  }

  public subscribeToast(severity: 'success' | 'error', contentName: string) {
    this.showToast(
      severity,
      severity == 'success' ? ActionState.Subscribed : ActionState.Error,
      severity === 'success'
        ? `${this.actionStateTranslations()[ActionState.Subscribed]} ${contentName} ${this.messageActionStateTranslations()[MessageActionState.Successfully]}.`
        : `${this.messageActionStateTranslations()[MessageActionState.ErrorSubscribed]} ${contentName}.`
    );

  }

  public removalToast(severity: 'success' | 'error', entityName: string) {
    this.showToast(
      severity,
      severity == 'success' ? ActionState.Deleted : ActionState.ErrorDeleted,
      severity === 'success'
        ? `${entityName} ${this.actionStateTranslations()[ActionState.Deleted]} ${this.messageActionStateTranslations()[MessageActionState.Successfully]}.`
        : `${this.messageActionStateTranslations()[MessageActionState.ErrorDeleted]} ${entityName}.`
    );
  }
}
