import {Entity, IEntity} from "./entity";
import {IOrganizationUnit, OrganizationUnit} from "./organization-unit";
import {ICompanySize} from "./company";

export interface IAssistanceType extends IEntity {
  name: string;
  nameKey?: string;
}

export interface IAssistanceStatus extends IEntity {
  name: string;
  nameKey?: string;
}

export interface IAssistanceRequest extends IEntity {
  description: string;
  firstName: string;
  lastName: string;
  comment: string | undefined;
  type: IAssistanceType | undefined;
  status: IAssistanceStatus | undefined;
  organizationUnit: IOrganizationUnit | undefined;
  topic: IAssistanceTopic | undefined;
}

export interface IAssistanceTopic extends IEntity {
  name: string;
  nameKey?: string;
}

export class AssistanceType extends Entity implements IAssistanceType {
  name: string;

  get nameKey(): string {
    return `assistanceRequest.type.${this.name}`;
  }

  constructor(data: IAssistanceType) {
    super(data);
    this.name = data.name;
  }
}

export class AssistanceStatus extends Entity implements IAssistanceStatus {
  name: string;

  get nameKey(): string {
    return `assistanceRequest.status.${this.name}`;
  }

  constructor(data: IAssistanceStatus) {
    super(data);
    this.name = data.name;
  }
}

export class AssistanceTopic extends Entity implements IAssistanceTopic {
  name: string;

  get nameKey(): string {
    return `assistanceTopic.types.${this.name}`;
  }

  constructor(data: IAssistanceTopic) {
    super(data);
    this.name = data.name;
  }
}

export class AssistanceRequest extends Entity implements IAssistanceRequest {
  description: string;
  firstName: string;
  lastName: string;
  comment: string | undefined;
  type: IAssistanceType | undefined;
  status: IAssistanceStatus | undefined;
  organizationUnit: IOrganizationUnit | undefined;
  topic: IAssistanceTopic | undefined;

  constructor(data: IAssistanceRequest) {
    super(data);
    this.description = data.description;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.comment = data.comment;
    this.type = data.type
      ? new AssistanceType(data.type)
      : undefined;
    this.status = data.status
      ? new AssistanceStatus(data.status)
      : undefined;
    this.organizationUnit = data.organizationUnit
      ? new OrganizationUnit(data.organizationUnit)
      : undefined;
    this.topic = data.topic
      ? new AssistanceTopic(data.topic)
      : undefined;
  }
}
