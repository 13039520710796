import {Entity, IEntity, OrganizationUnit} from '.';
import {OrganizationUnitType} from './organization-unit-type';

export interface IUser extends IEntity {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  username: string;
  address: string;
  postalCode: string;
  position: string;
  phoneNumber: string;
  isActive: boolean;
  organizationUnitType: OrganizationUnitType;
  organizationUnit: OrganizationUnit;
}

export class User extends Entity implements IUser {
  email: string;
  firstName: string;
  lastName: string;
  username: string;
  address: string;
  postalCode: string;
  position: string;
  phoneNumber: string;
  isActive: boolean;
  organizationUnitType: OrganizationUnitType;
  organizationUnit: OrganizationUnit;

  constructor(data: IUser) {
    super(data);
    this.email = data.email;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.username = data.username;
    this.address = data.address;
    this.position = data.position;
    this.postalCode = data.postalCode;
    this.phoneNumber = data.phoneNumber;
    this.isActive = data.isActive;
    this.organizationUnitType = data.organizationUnitType;
    this.organizationUnit = data.organizationUnit;
  }

  getFullName() {
    return `${this.firstName} ${this.lastName}`;
  }
}
