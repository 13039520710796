import {SelectionStatus, SelectionStatusData} from "./selection-status";
import {Entity} from "../../models";

export interface IPartialSelection<T extends Entity> {
  status: string;
  items: T[];
  organizationTypeId: string;
  communityTypeId?: string;
}

export class PartialSelection<T extends Entity> {
  status: SelectionStatus;
  items: T[] = [];
  object?: any;

  constructor(status: SelectionStatus) {
    this.status = status;
    this.items = new Array();
  }

  public static createWithStatus<T extends Entity>(status:SelectionStatus): PartialSelection<T> {
    return new PartialSelection<T>(status);
  }

  public static createNewNoneSelected<T extends Entity>(): PartialSelection<T>{
    return new PartialSelection(SelectionStatusData.none);
  }

  public static createNewAllSelected<T extends Entity>(): PartialSelection<T>{
    return new PartialSelection(SelectionStatusData.all);
  }

  public addItem(item: T){
    this.items.push(item);
  }

  public removeItem(item: T){
    const itemArray = this.items.filter(x => x.id == item.id);
    if(itemArray && itemArray.length == 1){
      const element = itemArray[0];
      const index = this.items.indexOf(element);
      if(index > -1){
        this.items.splice(index, 1);
      }
    }
  }

  public changeStatus(status: SelectionStatus){
    return PartialSelection.createWithStatus(status);
  }

  public isAll(): boolean{
    return this.status.equals(SelectionStatusData.all);
  }

  public isNone(): boolean{
    return this.status.equals(SelectionStatusData.none);
  }
}
