import { WritableSignal } from "@angular/core";
import { ToastService } from "../services";
import { ActionType } from "../models/types/action-types";
import { AbstractControl, ValidationErrors } from "@angular/forms";

export function finishSending(
  sending: WritableSignal<boolean>,
  severity: 'success' | 'error',
  entity: string,
  actionType: ActionType,
  toastService: ToastService
) {
  sending.set(false);

  switch (actionType) {
    case 'create':
      toastService.creationToast(severity, entity);
      break;
    case 'update':
      toastService.updateToast(severity, entity);
      break;
  }
}

export function passwordMatchValidator(group: AbstractControl): ValidationErrors | null {
  const password = group.get('password')?.value;
  const confirmPassword = group.get('confirmPassword')?.value;

  return password === confirmPassword ? null : { passwordMisMatch: true };
}

export function resetFormFields(form: AbstractControl, fields: string[]): void {
  fields.forEach(field => {
    const control = form.get(field);
    if (control) {
      control.setValue(null);
    }
  });
}
