import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ILanguage } from '../models';
import { ApiService } from '.';

@Injectable({
  providedIn: 'root',
})
export class LanguagesService extends ApiService {
  private readonly url = `${environment.api.baseUrl}/languages`;

  getAll(): Observable<Array<ILanguage>> {
    return this.get<Array<ILanguage>>(this.url);
  }
}
