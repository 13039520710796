import {AssistanceStatus, AssistanceType} from "./assistance";
import { TagSeverity, AssistanceStatusType } from "./types";

export class AssistanceData {
  static types: AssistanceType[] = [
    new AssistanceType({ id: "consultation", name: "consultation", createdAt: new Date(), updatedAt: new Date() }),
    new AssistanceType({ id: "support", name: "support", createdAt: new Date(), updatedAt: new Date() })
  ];

  static statuses: AssistanceStatus[] = [
    new AssistanceStatus({ id: "pending", name: "pending", createdAt: new Date(), updatedAt: new Date() }),
    new AssistanceStatus({ id: "attended", name: "attended", createdAt: new Date(), updatedAt: new Date() }),
    new AssistanceStatus({ id: "closed", name: "closed", createdAt: new Date(), updatedAt: new Date() })
  ];

  static getAllTypes(): AssistanceType[] {
    return AssistanceData.types;
  }

  static getAllStatuses(): AssistanceStatus[] {
    return AssistanceData.statuses;
  }

  static getTargetColor(status: AssistanceStatusType): TagSeverity {
    switch (status) {
      case "pending":
        return "warning";
      case "attended":
        return "success";
      case "closed":
        return "secondary";
      default:
        return "contrast";
    }
  }
}
